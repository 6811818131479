import Vue from 'vue'
import VueRouter from 'vue-router'
import navigationBar from '../views/home/navigationBar';
Vue.use(VueRouter)

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
const routes = [
  //登录
  { path: '/login', name: 'login', component: () => import('../views/login/index.vue') },
  { path: '/jump', name: 'jump', component: () => import('../views/home/jump.vue') },
  //注册
  { path: '/register', name: 'register', component: () => import('../views/login/register.vue') },
  //忘记密码
  { path: '/modify_password', name: 'modify_password', component: () => import('../views/login/modify_password.vue') },
  {
    path: '/', component: navigationBar, children: [
      //首页
      { path: '/', name: 'index', component: () => import('../views/home/index.vue'), meta: { keepAlive: true } },
      //资讯中心
      { path: '/news', name: 'news', component: () => import('../views/new/index.vue') },
      //资讯详情
      { path: '/newsDetails', name: 'newsDetails', component: () => import('../views/new/details.vue') },
      //个人中心
      {
        path: '/my', name: 'my', component: () => import('../views/my/index.vue'), children: [
          //个人资料
          { path: '/', name: 'personal', component: () => import('../views/my/personal.vue') },
          //实名认证
          { path: '/identity', name: 'identity', component: () => import('../views/my/identity.vue') },
          //会员认证
          { path: '/member', name: 'member', component: () => import('../views/my/member.vue') },
          //邀请用户
          { path: '/Invite_users', name: 'Invite_users', component: () => import('../views/my/Invite_users.vue') },
          //会员单位申请
          { path: '/member_unit', name: 'member_unit', component: () => import('../views/my/member_unit.vue') },
          //入会说明
          { path: '/description', name: 'description', component: () => import('../views/my/description.vue') },
          //会员地图
          { path: '/member_map', name: 'member_map', component: () => import('../views/my/member_map.vue') },
          //我的留言
          { path: '/feedback', name: 'feedback', component: () => import('../views/my/feedback.vue') },
          //我的发票
          { path: '/invoice', name: 'invoice', component: () => import('../views/my/invoice.vue') },
        ]
      },
      //理事申请
      { path: '/leochIndex', name: 'leochIndex', component: () => import('../views/Leoch_apply/index.vue') },
      //会员申请
      { path: '/memberIndex', name: 'memberIndex', component: () => import('../views/member_apply/index.vue') },
      //会员单位申请
      { path: '/memberUnitIndex', name: 'memberUnitIndex', component: () => import('../views/member_unit/index.vue') },
      //学会概况
      {
        path: '/learn', name: 'learn', component: () => import('../views/survey/index.vue'), children: [
          //学会简介章程
          { path: '/', name: 'survey', component: () => import('../views/survey/survey.vue'), meta: { keepAlive: true } },
          //现任领导简介
          { path: '/brief_introduction', name: 'brief_introduction', component: () => import('../views/survey/brief_introduction.vue'), meta: { keepAlive: true } },
          { path: '/adviser', name: 'adviser', component: () => import('../views/survey/adviser.vue'), meta: { keepAlive: true } },
          { path: '/director', name: 'director', component: () => import('../views/survey/director.vue'), meta: { keepAlive: true } },
          //党建工作
          { path: '/party_building', name: 'party_building', component: () => import('../views/survey/party_building.vue'), meta: { keepAlive: true } }
        ]
      },
      //会员查询
      { path: '/memberSearch', name: 'memberSearch', component: () => import('../views/member_zone/search.vue'), meta: { keepAlive: true } },
      //协会
      {
        path: '/association', name: 'association', component: () => import('../views/association/index.vue'), children: [
          //协会
          { path: '/', name: 'list', component: () => import('../views/association/list.vue'), meta: { keepAlive: true } },
        ]
      },
      //联系我们
      {
        path: '/contact_us', name: 'contact_us', component: () => import('../views/contact_us/index.vue'), children: [
          //我要留言
          { path: '/', name: 'leave_message', component: () => import('../views/contact_us/leave_message.vue'), meta: { keepAlive: true } },
          //答复列表
          { path: '/replys', name: 'replys', component: () => import('../views/contact_us/reply.vue'), meta: { keepAlive: true } },
          //答复详情
          { path: '/reply', name: 'reply', component: () => import('../views/contact_us/reply_detail.vue'), meta: { keepAlive: true } },
          //常见问题
          { path: '/common_problems', name: 'common_problems', component: () => import('../views/contact_us/common_problem.vue'), meta: { keepAlive: true } },
          //常见问题详情
          { path: '/common_problem', name: 'common_problem', component: () => import('../views/contact_us/common_problem_detail.vue'), meta: { keepAlive: true } },
          //数据统计
          { path: '/statistics', name: 'statistics', component: () => import('../views/contact_us/statistics.vue'), meta: { keepAlive: true } },
        ]
      },
      //从业职格
      {
        path: '/occupationalRequirements', name: 'occupationalRequirements', component: () => import('../views/occupationalRequirements/index'),
        children: [
          { path: '', name: 'ExamList', component: () => import('../views/occupationalRequirements/components/ExamList') },
          { path: 'VideoCourse', name: 'VideoCourse', component: () => import('../views/occupationalRequirements/components/VideoCourse') },
          { path: 'PublicityApplication', name: 'PublicityApplication', component: () => import('../views/occupationalRequirements/components/PublicityApplication') },
          { path: 'ScoreQuery', name: 'ScoreQuery', component: () => import('../views/occupationalRequirements/components/ScoreQuery') },
          { path: 'signupExamination', name: 'signupExamination', component: () => import('../views/occupationalRequirements/components/signupExamination') },],
      },
      //视频
      {
        path: '/video', name: 'video', component: () => import('../views/occupationalRequirements/components/videoPage'),
      },
      //考试
      {
        path: '/examination', name: 'examination', component: () => import('../views/occupationalRequirements/components/examination'),
      },
      //笔试考试
      {
        path: '/WrittenExamination', name: 'WrittenExamination', component: () => import('../views/occupationalRequirements/components/WrittenExamination'),
      },
      //专业考试
      {
        path: '/ProfessionalExamination', name: 'ProfessionalExamination', component: () => import('../views/occupationalRequirements/components/ProfessionalExamination'),
      },
      //申领提现
      {
        path: '/ApplyWithdrawal', name: 'ApplyWithdrawal', component: () => import('../views/ApplyWithdrawal/index'),
      },
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  window.scrollTo(0, 0);
  next()
})
export default router
