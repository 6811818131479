import Vue from 'vue'
import service from '@/utils/request'
const Coms = [
    {name: "Header",component: () => import ('@/components/header')},
    {name: "Upload",component: () => import ('@/components/Upload')},
    {name: "Uploads",component: () => import ('@/components/uploads')},
    {name: "Page",component: () => import ('@/components/page')},
    {name: "Menu",component: () => import ('@/components/menu')},
    {name: "Identify",component: () => import ('@/components/identify')},
]

export default {
    //导入公共组件
    vueComs(){
        Coms.forEach(item => {
            return Vue.component(item.name, item.component)
        })
    },
    getImg(img){
        if(typeof(img)!='object'&&img.indexOf('http')==-1){
            return service.defaults.baseURL+img
        }else{
            return img
        }
    },
    //时间转换
    formatter(row){
        let t = null
        if(typeof(row)=='object'){
            t = new Date(row.created_at);//row 表示一行数据, updateTime 表示要格式化的字段名称
        }else{
            t = new Date(row)
        }
        return t.getFullYear()+"-"+((t.getMonth()+1)<10?'0'+(t.getMonth()+1):(t.getMonth()+1))+"-"+(t.getDate()<10?'0'+t.getDate():t.getDate())+" "+(t.getHours()<10?'0'+t.getHours():t.getHours())+":"+(t.getMinutes()<10?'0'+t.getMinutes():t.getMinutes())+":"+(t.getSeconds()<10?'0'+t.getSeconds():t.getSeconds())
    },
    timestampToTime(timestamp) {
        var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
        var Y = date.getFullYear() + '-';

        var M = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1) + '-';

        var D = date.getDate() + ' ';

        var h = date.getHours() + ':';

        var m = date.getMinutes() + ':';

        var s = date.getSeconds();

        return Y+M+D;

    }
}