import axios from "axios";
import qs from 'qs';
import {Message,Loading} from 'element-ui'
import Router from '@/router/index'
// var url = 'http://192.168.3.2:8081/'
var url = 'http://sf.aiyuechen.com//'
const service = axios.create({
    baseURL:process.env.NODE_ENV==='production'?url:'/api',      //接口路径
    timeout: 30000, // 请求超时时间
    withCredentials: true,
})

let loading = void 0;
function startLoading(){
    loading = Loading.service({
        lock:true,
        text:'加载中....',
        background:'rgba(0,0,0,0.4)'
    })
}
function endLoading(){
    loading.close()
}

let lRequestCount = 0;

function showFullScreenLoading(){
    if(lRequestCount === 0) startLoading();
    ++lRequestCount;
}
function tryHideFullScreenLoading(){
    if(lRequestCount <=0 ) return
    --lRequestCount;
    if(lRequestCount === 0) endLoading();
}


// 请求前拦截
service.interceptors.request.use(
    config => {
        config.headers["Content-Type"] = "multipart/form-data";
        if (localStorage.getItem('sheixetoken')) {
            config.headers['Authorization'] = "Bearer " + localStorage.getItem('sheixetoken');
        }
        if(config.url.indexOf('api/v1/payment/check-order/')==-1 && config.url!='api/member_order/pay_result'){
            if(!config.silent){
                showFullScreenLoading();
            }
        }
        if(config.method=='post'||config.method=='put'){
            if(config.headers['Content-Type'] === 'multipart/form-data'){
                let formData = new FormData()
                for(let i in config.data){
                    if(config.data[i]!=null){
                        formData.append(i,config.data[i]);
                    }
                }
                config.data = formData
            }
        }else{
            config.data = qs.stringify(config.data);
        }
        return config;
    },
    err => {
        return Promise.reject(err);
    }
);
// 返回后拦截
service.interceptors.response.use(
    data => {
        if(!data.config.silent){
            tryHideFullScreenLoading();
        }
        //正常200 || 无数据201 || 权限不足;
        //  || data.data.code == 202
        if (data.data.code == 200 || data.data.code == 201 || data.config.url=='api/member_order/pay_result') {
            return data.data;
        }
        //401:token失效
       if(data.data.code == 401 || data.data.code == 402){
            localStorage.removeItem('sheixetoken')
            localStorage.removeItem('oneFrom')
            localStorage.removeItem('twoFrom')
            localStorage.removeItem('threeFrom')
            localStorage.removeItem('oneFrom1')
            localStorage.removeItem('twoFrom1')
            localStorage.removeItem('oneFrom2')
            localStorage.removeItem('userid')
            localStorage.removeItem('twoFrom2')
            Router.replace({
                path:'/login',
                query:{
                    redirect:window.location.hash.replace('#','')
                }
            });
       }else if(data.data.code == 403){
            Router.push({
                path:'/403',
            });
       }else{
            Message({message: data.data.msg,type: 'error',duration: 1 * 1500});
       }
       return Promise.reject(data.data);
    },
    err=>{
        if(err.response&&err.response.status==404){
            Router.push({
                path:'/404',
            });
        }
        tryHideFullScreenLoading();
    }
);
export default service;