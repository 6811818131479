<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
	created:function(){
		// 百度统计
		var _hmt = _hmt || [];
		var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?3ea0f0fa3286114ee7e3d2ec49597ec6";
    var s = document.getElementsByTagName("script")[0]; 
    s.parentNode.insertBefore(hm, s);
	}
}
</script>
<style lang="scss">
@import '~@/assets/css/common.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  min-width:1200px;
}
.el-radio__input.is-checked + .el-radio__label { 
    color: #CC0000 !important;
} 
  /* 选中后小圆点的颜色 */ 
.el-radio__input.is-checked .el-radio__inner {
    background: #CC0000 !important; 
    border-color: #CC0000 !important; 
}
.operation{
  cursor: pointer;
}
</style>
