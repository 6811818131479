<template>
    <div>
        <Header></Header>
        <Menu v-if="$route.meta.keepAlive"></Menu>
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    data() {
        return {

        };
    },
    created() {

    },
    mounted() {

    },
    methods: {

    }
};
</script>

<style scoped lang="scss">

</style>
